export const CLIENT_VIEW = {
    id: 'client',
    labelKey: 'glossary:client_other',
};
export const ACCOUNT_VIEW = {
    id: 'account',
    labelKey: 'glossary:account_other',
};
export const FUND_VIEW = {
    id: 'fund',
    labelKey: 'glossary:fund_other',
};
export const VIEWS = [CLIENT_VIEW, ACCOUNT_VIEW, FUND_VIEW];
