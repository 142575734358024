import {
  DEFINITION_SET_DOCUMENT_TYPES,
  DEFINITION_SET_FUNDS,
  DEFINITION_SET_WHITE_LABEL_PARTNERS,
  DEFINITION_SET_DEFINITION_TYPES,
  DEFINITION_SET_SIGNER_TYPES,
  DEFINITION_SET_ACCOUNT_TYPES,
  DEFINITION_SET_LIBRARY_TEMPLATES,
  DEFINITION_SET_REQUIRED_DOCUMENT_TYPES,
} from 'actions/actionsConstants';

import update from 'immutability-helper';
import initialState from '../initialState';

export default (state = initialState, action) => {
  switch (action.type) {
    case DEFINITION_SET_DOCUMENT_TYPES:
      return update(state, { documentTypes: { $set: action.payload.types } });
    case DEFINITION_SET_FUNDS:
      return update(state, { funds: { $set: action.payload } });
    case DEFINITION_SET_WHITE_LABEL_PARTNERS:
      return update(state, { whiteLabelPartners: { $set: action.payload } });
    case DEFINITION_SET_REQUIRED_DOCUMENT_TYPES:
      return update(state, {
        requiredDocumentTypes: { $set: action.payload },
        requiredDocumentTypesIsLoading: { $set: false },
      });
    case DEFINITION_SET_DEFINITION_TYPES:
      return update(state, { subDocTypes: { $set: action.payload } });
    case DEFINITION_SET_SIGNER_TYPES:
      return update(state, { signerTypes: { $set: action.payload } });
    case DEFINITION_SET_ACCOUNT_TYPES:
      return update(state, { accountTypes: { $set: action.payload } });
    case DEFINITION_SET_LIBRARY_TEMPLATES:
      return update(state, { libraryTemplates: { $set: action.payload.data } });
    default:
      return state;
  }
};
